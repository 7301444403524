import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-unauthenticated',
	templateUrl: './unauthenticated.component.html',
})
export class UnauthenticatedComponent {
	constructor(public dialogRef: MatDialogRef<UnauthenticatedComponent>) {}
	close() {
		this.dialogRef.close();
	}
}
