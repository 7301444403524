import { Timestamps, ResidentialComplex, Media } from '@models';

export interface Event extends Timestamps {
	id: number;
	residential_id: number;
	title: string;
	description: string;
	start_date: string;
	end_date: string;
	priority: string;
	priority_name: string;
	type_id: number;
	type_name?: string;
	residential_complex?: ResidentialComplex;
	media?: Media[];
}

export interface EventFilter {
	start_date: string;
	end_date: string;
	priority: number;
	type_id: number;
}

export interface EventType extends Timestamps {
	id: number;
	name: string;
	status?: string;
	icon?: string;
}

export enum EventTypeId {
	Evento = 1,
	Comunicado = 2,
	Incidencia = 3,
}

export enum EventTypeName {
	Evento = 'Evento',
	Comunicado = 'Comunicado',
	Incidencia = 'Incidencia',
}

export const EventTypes: Readonly<EventType[]> = [
	{
		id: EventTypeId.Evento,
		name: EventTypeName.Evento,
		icon: 'exclamation-circle',
	},
	{
		id: EventTypeId.Comunicado,
		name: EventTypeName.Comunicado,
		icon: 'exclamation-circle',
	},
	{
		id: EventTypeId.Incidencia,
		name: EventTypeName.Incidencia,
		icon: 'exclamation-circle',
	},
] as const;

export interface EventPriority extends Timestamps {
	id: number;
	name: string;
	status?: string;
	icon?: string;
}

export enum EventPriorityId {
	Baja = 1,
	Media = 2,
	Alta = 3,
}
export enum EventPriorityName {
	Baja = 'Baja',
	Media = 'Media',
	Alta = 'Alta',
}

export const EventPriorities: Readonly<EventPriority[]> = [
	{
		id: EventPriorityId.Baja,
		name: EventPriorityName.Baja,
	},
	{
		id: EventPriorityId.Media,
		name: EventPriorityName.Media,
	},
	{
		id: EventPriorityId.Alta,
		name: EventPriorityName.Alta,
	},
] as const;
export interface EventWhere {
	[key: string]: [string, string, string | number | null];
}
export interface EventWhereIn {
	[key: string]: [string, number[]];
}
