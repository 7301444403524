export interface Rol {
	id: RolId;
	name: RolName;
	cod: RolCod;
	options?: any;
	type?: RolType;
	selected?: boolean;
	disabled?: boolean;
}
export enum RolId {
	Admin = 1,
	Support = 2,
	AdministrativeResidentialComplexManager = 3,
	ResidentialComplexManager = 4,
	AdministrativeStaff = 5,
	Resident = 6,
	Doorkeeper = 7,
	CompanyManager = 8,
}
export enum RolType {
	Wise = 'Wise',
	Administrative = 'Administrative',
	Final = 'Final',
}
export enum RolName {
	Admin = 'Admin',
	Support = 'Soporte',
	CompanyManager = 'Administrador de empresa',
	AdministrativeResidentialComplexManager = 'Administrador de conjunto residencial',
	ResidentialComplexManager = 'Gerente de conjunto residencial',
	AdministrativeStaff = 'Personal administrativo',
	Resident = 'Residente',
	Doorkeeper = 'Portero',
}
export enum RolCod {
	Admin = 'admin',
	Support = 'support',
	CompanyManager = 'company_manager',
	AdministrativeResidentialComplexManager = 'Administrative_residential_complex_manager',
	ResidentialComplexManager = 'residential_complex_manager',
	AdministrativeStaff = 'administrative_staff',
	Resident = 'resident',
	Doorkeeper = 'doorkeeper',
}

export const Roles: Rol[] = [
	{
		id: RolId.Admin,
		cod: RolCod.Admin,
		name: RolName.Admin,
		options: '',
		type: RolType.Wise,
		disabled: false,
	},
	{
		id: RolId.Support,
		cod: RolCod.Support,
		name: RolName.Support,
		options: '',
		type: RolType.Wise,
		disabled: false,
	},
	{
		id: RolId.CompanyManager,
		cod: RolCod.CompanyManager,
		name: RolName.CompanyManager,
		options: '',
		type: RolType.Administrative,
		disabled: false,
	},
	{
		id: RolId.AdministrativeResidentialComplexManager,
		cod: RolCod.AdministrativeResidentialComplexManager,
		name: RolName.AdministrativeResidentialComplexManager,
		options: '',
		type: RolType.Administrative,
		disabled: false,
	},
	{
		id: RolId.ResidentialComplexManager,
		cod: RolCod.ResidentialComplexManager,
		name: RolName.ResidentialComplexManager,
		options: '',
		type: RolType.Administrative,
		disabled: false,
	},
	{
		id: RolId.AdministrativeStaff,
		cod: RolCod.AdministrativeStaff,
		name: RolName.AdministrativeStaff,
		options: '',
		type: RolType.Administrative,
		disabled: false,
	},
	{
		id: RolId.Resident,
		cod: RolCod.Resident,
		name: RolName.Resident,
		options: '',
		type: RolType.Final,
		disabled: false,
	},
	{
		id: RolId.Doorkeeper,
		cod: RolCod.Doorkeeper,
		name: RolName.Doorkeeper,
		options: '',
		type: RolType.Administrative,
		disabled: false,
	},
];

export const RolesAdministrative: Readonly<Rol[]> = Roles.filter(
	(e) => e.type === RolType.Administrative
);

export const RolesWise: Readonly<Rol[]> = Roles.filter((e) => e.type === RolType.Wise);
