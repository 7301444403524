import { Timestamps, Status, Company, Country, User, Event } from '@models';

export interface ResidentialComplexResidence extends Timestamps {
	id: number;
	group_id: string;
	num_id: string;
	address: string;
	level: string;
	status?: boolean;
	group?: ResidentialComplexGroup;
}
export interface ResidentialComplexResidenceCreate {
	num_id: string;
	address: string;
	level: string;
}
export interface ResidentialComplexGroupFormat {
	digit_format: number;
	start_floor: number;
	prefix_format: number;
	prefix_separator?: string;
	floor_separator?: string;
}
export interface ResidentialComplexGroupType extends Timestamps {
	id: number;
	name: string;
	status?: string;
}
export enum ResidentialComplexGroupTypeName {
	Edificio = 'Edificio',
	Calle = 'Calle',
	Manzana = 'Manzana',
}

export enum ResidentialComplexGroupTypeId {
	Edificio = 1,
	Calle = 2,
	Manzana = 3,
}

export interface ResidentialComplexGroup extends Timestamps {
	id: number;
	name: string;
	residential_id: number;
	type_id: number;
	nomenclature: string;
	levels: number;
	type?: ResidentialComplexGroupType;
	type_name?: string;
	residential_complex?: ResidentialComplex;
	residences?: ResidentialComplexResidence[];
	residences_count: number;
	group_format?: ResidentialComplexGroupFormat;
}
export interface ResidentialComplexGroupDetails {
	residences: ResidentialComplexResidence[];
	format_residence: ResidentialComplexGroupFormat[];
}
export interface ResidentialComplexGroupCreate {
	name: string;
	residential_id: number;
	type_id: number;
	nomenclature: string;
	levels: number;
	residences_count: number;
}

export interface ResidentialComplex extends Timestamps {
	id: number;
	name: string;
	address: string;
	latitude: string;
	longitude: string;
	company_id: number;
	company_name: string;
	country_id: number;
	country_name: string;
	status_id: ResidentialComplexStatusId;
	status: ResidentialComplexStatusName;
	company?: Company;
	country?: Country;
	groups?: ResidentialComplexGroup[];
	groups_count?: number;
	residences?: ResidentialComplexResidence[];
	residences_count?: number;
	events?: Event[];
	events_count?: number;
	users?: User[];
	users_count?: number;
}

export enum ResidentialComplexStatusName {
	Activo = 'Activo',
	Inactivo = 'Inactivo',
	Bloqueado = 'Bloqueado',
}

export enum ResidentialComplexStatusId {
	Activo = 1,
	Inactivo = 2,
	Bloqueado = 3,
}

export const ResidentialComplexGroupTypes: Readonly<ResidentialComplexGroupType[]> = [
	{
		id: ResidentialComplexGroupTypeId.Edificio,
		name: ResidentialComplexGroupTypeName.Edificio,
	},
	{
		id: ResidentialComplexGroupTypeId.Calle,
		name: ResidentialComplexGroupTypeName.Calle,
	},
	{
		id: ResidentialComplexGroupTypeId.Manzana,
		name: ResidentialComplexGroupTypeName.Manzana,
	},
] as const;

export const ResidentialComplexStatus: Readonly<Status[]> = [
	{
		id: ResidentialComplexStatusId.Activo,
		name: ResidentialComplexStatusName.Activo,
	},
	{
		id: ResidentialComplexStatusId.Inactivo,
		name: ResidentialComplexStatusName.Inactivo,
	},
	{
		id: ResidentialComplexStatusId.Bloqueado,
		name: ResidentialComplexStatusName.Bloqueado,
	},
] as const;

export interface TopTenResidentsByResidence {
	id: number;
	name: string;
	cant_resident: number;
}

export interface TopTenVisits {
	id: number;
	name: string;
	cant_visits: number;
}
export interface TopTenResidentialCorrespondence {
	id: number;
	name: string;
	cant_correspondence: number;
}

// "cant_correspondence": 3
//
