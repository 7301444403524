import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '@services/users/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UnauthorizedComponent } from '@shared/components/dialog/unauthorized/unauthorized.component';
import { UnauthenticatedComponent } from '@components/dialog/unauthenticated/unauthenticated.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private dialog: MatDialog, private userService: UserService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.status === 401) {
					const dialogRef = this.dialog.open(UnauthenticatedComponent, { disableClose: true });
					dialogRef.afterClosed().subscribe(() => this.userService._logOut());
				}
				if (err.status === 403) {
					this.dialog.open(UnauthorizedComponent);
					return throwError(() => err.error);
				}
				return throwError(() => err.error);
			})
		);
	}
}
