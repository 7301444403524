import { Rol, Timestamps, Status, ResidentialComplex, ResidentialComplexResidence } from '@models';

export interface User extends Timestamps {
	id: number;
	email: string;
	email_verified_at: string | null;
	name: string;
	phone: string;
	status_id: UserStatusId;
	status: string;
	created_at: string;
	roles: Rol[];
	residential_complexes?: ResidentialComplex[];
	residences?: ResidentialComplexResidence[];
	currentStatus?: string;
	currentRol?: Rol;
	pivot: {
		rol_id?: number;
		status?: number;
		user_id?: number;
		userable_id?: number;
		userable_type?: string;
	};
}

export enum UserStatusName {
	Activo = 'Activo',
	Inactivo = 'Inactivo',
	Bloqueado = 'Bloqueado',
}

export enum UserStatusId {
	Activo = 1,
	Inactivo = 2,
	Bloqueado = 3,
}

export const UserStatus: Readonly<Status[]> = [
	{
		id: UserStatusId.Activo,
		name: UserStatusName.Activo,
	},
	{
		id: UserStatusId.Inactivo,
		name: UserStatusName.Inactivo,
	},
	{
		id: UserStatusId.Bloqueado,
		name: UserStatusName.Bloqueado,
	},
] as const;

export interface UserUpdatePassword {
	password: string;
	new_password: string;
	new_password_confirmation: string;
}
export interface UserRecoverPassword {
	token: string;
	new_password: string;
	new_password_confirmation: string;
}

export interface TotalUsers {
	total_users: number;
}
export interface TotalUsers {
	total_users: number;
}
// status_id
export interface DataPoint {
	day: number;
	users: number;
}

export interface MonthlyDataUsers {
	month: string;
	series: DataPoint[];
}
export interface FilterUsers {
	// status_id?: number;
	year?: number;
	month?: number;
	start_date?: string;
	end_date?: string;
}
export interface LogActivityUsers {
	user_id: number;
	name: string;
	email: string;
	activity_count: number;
}
