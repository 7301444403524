import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthenticationGuard } from '@guards/authentication.guard';
import { PageTitleService } from '@services/page-title/page-title.service';

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthenticationGuard],
		loadChildren: () =>
			import('./pages/common-layout/common-layout.module').then((m) => m.CommonLayoutModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'recover-password',
		loadChildren: () =>
			import('@pages/recover-password/recover-password.module').then(
				(m) => m.RecoverPasswordModule
			),
	},
	{ path: '**', redirectTo: '' },
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
	exports: [RouterModule],
	providers: [{ provide: TitleStrategy, useClass: PageTitleService }],
})
export class AppRoutingModule {}
