import { Media } from './media';
import { Resident } from './resident';
import { ResidentialComplexResidence } from './residentialComplex';
import { Timestamps } from './timestamps';
import { User } from './user';

export interface Correspondence extends Timestamps {
	id: number;
	residence_id: number;
	resident_id: number;
	type_id: number;
	status_id: number;
	withdrawn_by: number;
	type_name: string;
	status: string;
	comments?: string;
	received_by?: number | User;
	residence?: ResidentialComplexResidence;
	resident: Resident
	media?: Media[];
}

export interface CorrespondenceFilter {
	start_date: string;
	end_date: string;
	type_id: number;
}

export interface CorrespondenceType extends Timestamps {
	id: number;
	name: string;
	icon?: string;
}
export enum CorrespondenceTypeId {
	Correspondencia = 1,
	Delivery = 2,
	Encomienda = 3,
}
export enum CorrespondenceTypeName {
	Correspondencia = 'Correspondencia',
	Delivery = 'Delivery',
	Encomienda = 'Encomienda',
}

export interface CorrespondenceStatus extends Timestamps {
	id: number;
	name: string;
	icon?: string;
}

export enum CorrespondenceStatusId {
	Espera = 1,
	Recibida = 2,
	Retirada = 3,
}

export enum CorrespondenceStatusName {
	Espera = 'Espera',
	Recibida = 'Recibida',
	Retirada = 'Retirada',
}

export const CorrespondenceStatus: Readonly<CorrespondenceStatus[]> = [
	{
		id: CorrespondenceStatusId.Espera,
		name: CorrespondenceStatusName.Espera,
	},
	{
		id: CorrespondenceStatusId.Recibida,
		name: CorrespondenceStatusName.Recibida,
	},
	{
		id: CorrespondenceStatusId.Retirada,
		name: CorrespondenceStatusName.Retirada,
	},
] as const;
export const CorrespondenceTypes: Readonly<CorrespondenceType[]> = [
	{
		id: CorrespondenceTypeId.Correspondencia,
		name: CorrespondenceTypeName.Correspondencia,
	},
	{
		id: CorrespondenceTypeId.Delivery,
		name: CorrespondenceTypeName.Delivery,
	},
	{
		id: CorrespondenceTypeId.Encomienda,
		name: CorrespondenceTypeName.Encomienda,
	},
] as const;
export interface CorrespondenceWhere {
	[key: string]: [string, string, string | number | null];
}
export interface CorrespondenceWhereIn {
	[key: string]: [string, number[]];
}
export interface DataPoint {
	day: number;
	correspondence: number;
}

export interface MonthlyDataCorrespondence {
	month: string;
	series: DataPoint[];
}
export interface FilterCorrespondence {
	status_id?: number;
	year?: number;
	month?: number;
	start_date?: string;
	end_date?: string;
}