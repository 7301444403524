import { Timestamps, Status, User, ResidentialComplex } from '@models';

export interface Company extends Timestamps {
	id: number;
	name: string;
	business_name: string;
	num_id: string;
	email: string;
	address: string;
	country_id: number;
	country_name: string;
	status_id: CompanyStatusId;
	status: CompanyStatusName;
	users?: User[];
	users_count?: number;
	residential_complexes?: ResidentialComplex;
	residential_complexes_count?: number;
}

export enum CompanyStatusId {
	Activa = 1,
	Inactiva = 2,
	Bloqueada = 3,
}

export enum CompanyStatusName {
	Activa = 'Activa',
	Inactiva = 'Inactiva',
	Bloqueada = 'Bloqueada',
}

export const CompanyStatus: Readonly<Status[]> = [
	{
		id: CompanyStatusId.Activa,
		name: CompanyStatusName.Activa,
	},
	{
		id: CompanyStatusId.Inactiva,
		name: CompanyStatusName.Inactiva,
	},
	{
		id: CompanyStatusId.Bloqueada,
		name: CompanyStatusName.Bloqueada,
	},
] as const;

export interface ResidentialCount {
	name: string;
	business_name: string;
	residential_complexes_count: number;
	country: string;
  }