import { Timestamps, User, ResidentialComplexResidence } from '@models';

export interface Visit extends Timestamps {
	id: number;
	residence_id: number;
	type_id: number;
	type_name: string;
	name: string;
	num_id: string;
	num_id_vehicle?: string;
	type_vehicle?: string;
	color_vehicle?: string;
	company_name?: string;
	start_date?: string;
	parking_lot?: string;
	comments?: string;
	residence?: ResidentialComplexResidence;
	received_by?: User;
	selected?: boolean;
	icon?: string;
}

export interface VisitFilter {
	start_date: string;
	end_date: string;
	type_id: number;
}

export interface VisitType extends Timestamps {
	id: number;
	name: string;
	icon?: string;
}

export enum VisitTypeId {
	Visita = 1,
	Servicio = 2,
	Delivery = 3,
}
export enum VisitTypeName {
	Visita = 'Visita',
	Servicio = 'Servicio',
	Delivery = 'Delivery',
}

export interface VisitStatus extends Timestamps {
	id: number;
	name: string;
	icon?: string;
}

export enum VisitStatusId {
	NoAprobado = 1,
	Aprobado = 2,
}

export enum VisitStatusName {
	NoAprobado = 'No Aprobado',
	Aprobado = 'Aprobado',
}

export const VisitStatus: Readonly<VisitStatus[]> = [
	{
		id: VisitStatusId.NoAprobado,
		name: VisitStatusName.NoAprobado,
	},
	{
		id: VisitStatusId.Aprobado,
		name: VisitStatusName.Aprobado,
	},
] as const;

export const VisitTypes: Readonly<VisitType[]> = [
	{
		id: VisitTypeId.Visita,
		name: VisitTypeName.Visita,
		icon: 'user',
	},
	{
		id: VisitTypeId.Servicio,
		name: VisitTypeName.Servicio,
		icon: 'tools',
	},
	{
		id: VisitTypeId.Delivery,
		name: VisitTypeName.Delivery,
		icon: 'shopping-cart',
	},
] as const;

export interface VisitWhere {
	[key: string]: [string, string, string | number | null];
}
export interface DataPoint {
	day: number;
	visits: number;
}

export interface MonthlyDataVisit {
	month: string;
	series: DataPoint[];
}
export interface FilterVisit {
	status_id?: number;
	year?: number;
	month?: number;
	start_date?: string;
	end_date?: string;
}
export interface TotalVisits {
	total_visits: number;
}