import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
	constructor(public dialogRef: MatDialogRef<UnauthorizedComponent>) {}
	close() {
		this.dialogRef.close();
	}
}
