import { Timestamps } from '@models';
export interface Media extends Timestamps {
	id?: number;
	file?: string;
	file_name?: string;
	file_type: string;
	file_ext: string;
	file_size?: string;
	is_image: '1' | '2';
	alt?: string;
	description?: string;
	file_path?: string;
}
export interface FileType {
	name: string;
	file_ext: string;
	mime: string;
}
export interface MediaSubmit {
	files: Media[];
}

export const allowedFiles: Readonly<FileType[]> = [
	{
		name: 'JPG',
		file_ext: '.jpg',
		mime: 'image/jpg',
	},
	{
		name: 'JPEG',
		file_ext: '.jpeg',
		mime: 'image/jpeg',
	},
	{
		name: 'PNG',
		file_ext: '.png',
		mime: 'image/png',
	},
	{
		name: 'PDF',
		file_ext: '.pdf',
		mime: 'application/pdf',
	},
] as const;
