import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class PageTitleService extends TitleStrategy {
	private titleSubject$ = new BehaviorSubject<string | undefined>('Wise Checkin');
	pageTitle = this.titleSubject$.asObservable();
	constructor(private readonly title: Title) {
		super();
	}
	updateTitle(routerState: RouterStateSnapshot) {
		const title = this.buildTitle(routerState);
		if (title !== undefined) {
			this.title.setTitle(`Wise Checkin | ${title}`);
		}
	}
	get title$(): Observable<string | undefined> {
		return this.titleSubject$.asObservable();
	}
	set(title: string | undefined) {
		this.titleSubject$.next(title);
	}
}
