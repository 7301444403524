import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/users/user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
	constructor(private userService: UserService, public router: Router) {}
	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (this.userService.token) {
			return true;
		} else {
			this.router.navigate(['/login']);
		}
		return false;
	}
}
