import { Timestamps, ResidentialComplexResidence, Status, User } from '@models';

export interface Resident extends Timestamps {
	id: number;
	residence_id: number;
	name: string;
	num_id: string;
	type_id: ResidentTypeId;
	user_id: number;
	type_name?: string;
	status_id?: string;
	status?: ResidentStatusName;
	residence?: ResidentialComplexResidence;
	user?: User;
}

export interface ResidentType {
	id: number;
	name: string;
}

export enum ResidentTypeId {
	Residente = 1,
	Empleado = 2,
	Responsable = 3,
}
export enum ResidentTypeName {
	Residente = 'Residente',
	Empleado = 'Empleado',
	Responsable = 'Responsable',
}

export const ResidentTypes: Readonly<ResidentType[]> = [
	{
		id: ResidentTypeId.Residente,
		name: ResidentTypeName.Residente,
	},
	{
		id: ResidentTypeId.Empleado,
		name: ResidentTypeName.Empleado,
	},
	{
		id: ResidentTypeId.Responsable,
		name: ResidentTypeName.Responsable,
	},
] as const;

export enum ResidentStatusId {
	Activo = 1,
	Inactivo = 2,
}

export enum ResidentStatusName {
	Activa = 'Activo',
	Inactiva = 'Inactivo',
}

export const ResidentStatus: Readonly<Status[]> = [
	{
		id: ResidentStatusId.Activo,
		name: ResidentStatusName.Activa,
	},
	{
		id: ResidentStatusId.Inactivo,
		name: ResidentStatusName.Inactiva,
	},
] as const;
export interface ResidentWhere {
	[key: string]: [string, string, string | number | null];
}
export interface ResidentWhereIn {
	[key: string]: [string, number[]];
}
export interface DataPoint {
	day: number;
	residents: number;
}

export interface TotalResidents {
    total_residents: number;
}

export interface FilterResidents {
	year?: number;
	month?: number;
	start_date?: string;
	end_date?: string;
}

export interface MonthlyDataResidents {
	month: string;
	series: DataPoint[];
}