import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import {
	User,
	RolId,
	LoginResponse,
	Token,
	UserUpdatePassword,
	Rol,
	FilterUsers,
	TotalUsers,
} from '@models';

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LogActivityUsers, MonthlyDataUsers } from 'app/models/user';
@Injectable({
	providedIn: 'root',
})
export class UserService {
	private base_url: string;
	private usersSubject$ = new BehaviorSubject<User[]>([]);
	private usersSubjectError$ = new BehaviorSubject<string>('');
	public users$: Observable<User[]>;
	public usersError$: Observable<string>;

	public user: User | null = null;
	public token: string | null = null;
	public end_point: string;

	constructor(private router: Router, private http: HttpClient) {
		this.end_point = 'user';
		this.base_url = `${environment.base_url}/api/${this.end_point}`;
		this.users$ = this.usersSubject$.asObservable();
		this.usersError$ = this.usersSubjectError$.asObservable();
		this.token = localStorage.getItem('token');
		this.user = JSON.parse(localStorage.getItem('user') as string);
	}
	loadToSelect(): void {
		if (this.usersSubject$.getValue().length === 0) {
			this.http.get<User[]>(this.base_url).subscribe({
				next: (users) => this.usersSubject$.next(users),
				error: (e: HttpErrorResponse) => this.usersSubjectError$.next(e.message),
			});
		}
	}
	logOut(): void {
		this.http.post(`${environment.base_url}/api/auth/logout`, {}).subscribe();
		this._logOut();
	}
	_logOut(): void {
		localStorage.clear();
		this.user = null;
		this.token = null;
		this.router.navigate(['/login']);
	}
	save(user: User, token: string): void {
		this.user = user;
		this.token = token;
		localStorage.setItem('user', JSON.stringify(this.user));
		localStorage.setItem('token', this.token);
	}

	login(value: User) {
		return this.http.post<LoginResponse>(`${environment.base_url}/api/auth/login/web`, value);
	}


	all() {
		return this.http.get<User[]>(this.base_url);
	}

	get(id: User['id']) {
		return this.http.get<User>(`${this.base_url}/${id}`);
	}

	store(value: User) {
		return this.http.post<User>(this.base_url, value);
	}

	update(id: User['id'], value: User) {
		return this.http.put<User>(`${this.base_url}/${id}`, value);
	}
	updateRoles(id: User['id'], value: UserUpdatePassword) {
		return this.http.put<Rol[]>(`${this.base_url}/roles/${id}`, value);
	}
	updatePassword(id: User['id'], value: UserUpdatePassword) {
		return this.http.put<User>(`${this.base_url}/password/${id}`, value);
	}
	tokens() {
		return this.http.get<Token[]>(`${this.base_url}/tokens`);
	}
	tokenDelete(token: Token) {
		return this.http.delete<Token>(`${this.base_url}/tokens/${token.id}`);
	}
	destroy(id: User['id']) {
		return this.http.delete<User>(`${this.base_url}/${id}`);
	}
	filter(filter: FilterUsers): Observable<TotalUsers> {
		return this.http.post<any>(`${this.base_url}/filter/total-users`, filter);
	}
	filterByDay(filter: FilterUsers): Observable<MonthlyDataUsers[]> {
		return this.http.post<MonthlyDataUsers[]>(`${this.base_url}/filter/users-by-day`, filter);
	}
	log(): Observable<LogActivityUsers[]> {
		return this.http.post<LogActivityUsers[]>(`${this.base_url}/metrics`, null);
	}
	topTenLog(): Observable<LogActivityUsers[]> {
		return this.http.post<LogActivityUsers[]>(`${this.base_url}/top-ten-activityLogs`, null);
	}
	get isAdmin(): boolean {
		if (!this.user) {
			return false;
		}
		return this.user.roles.some((rol) => rol.id == RolId.Admin);
	}
}
